import { useEffect, useState, Fragment, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Trans, useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import Container from '@mui/material/Container';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';

import './index.scss';

import CheckListItem from '../RecipeAccordion/CheckListItem';
import RetailerContext from '../../context';
import { Box, DialogContent } from '@mui/material';

import updateProvince from '../../actions/Province';
import api from '../../api';
import { getTranslation } from '../../utils/translate';
import { Language } from '@mui/icons-material';
import { pushToGoogleAnalytics } from '../../utils/google-analytics';

function ProvinceDialog(props) {
  const { t } = useTranslation(['translations', 'provinces']);;
  const dispatch = useDispatch();
  const { retailer } = useContext(RetailerContext);

  const selectedProvince = useSelector(state => state.province);

  const { onClose, selectedValue, open, provinces = [] } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (province) => {
    pushToGoogleAnalytics({
      event: 'storeSelection',
      text: province.name,
    });

    onClose(province);
    dispatch(updateProvince(province));
  };

  return (
    <Dialog
      className='province-switcher'
      onClose={handleClose}
      open={open}
    >
      <DialogTitle
          sx={{
              boxShadow: '0 16px 15px 0 rgba(0, 0, 0, 0.18)',
              py: 3,
          }}
          component={'div'}
      >
          <Typography
            className='title'
              variant="h1"
              sx={{
                  color: retailer.brand_color,
                  fontSize: '32px',
                  fontWeight: 'bolder',
                  textTransform: 'uppercase',
                  mr: 1 
              }}
          >
          {t('province_chooseYourProvince') }
          </Typography>
      </DialogTitle>
      <DialogContent sx={{ pt: 2, px: 0 }}>
        <Container
          sx={{
            mt: 4
          }}
        >
          <Trans>
            {t('province_chooseYourProvinceMessage')}
          </Trans>
        </Container>
        <List sx={{ mt: 2 }}>
          {provinces.map((province) => (
            <Fragment key={province.name}>
              <Container
                onClick={() => handleListItemClick(province)}
              >
                <CheckListItem
                  checked={province.name === selectedProvince.name}
                  item={{ name: t(province.name, { ns: 'provinces' }) }}
                />
              </Container>
            </Fragment>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
}

Dialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.object,
};

export default function ProvinceSwitcher() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const province = useSelector(state => state.province);
  const { code: languageCode } = useSelector(state => state.language);

  const { retailer } = useContext(RetailerContext);

  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const [provinces, setProvinces] = useState([]);

  const defaultProvince = {
    name: 'Global',
    longName: t('global'),
    image: `${process.env.REACT_APP_API_URL}/assets/${retailer.logo.id}?key=system-large-contain`,
    imageId: retailer?.logo?.id,
    store_url: retailer.store_url,
    domain: null,
    store_label: getTranslation(retailer, { key: 'languages_code', code: languageCode })?.retailer_name,
  };

  const subdomain = process.env.NODE_ENV === 'production' ?
    window.location.hostname :
    process.env.REACT_APP_BASE_URL
  ;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  useEffect(async () => {
    try {
      // fetch list of configured province settings
      const provincesResponse = await api.getListProvinces({
        filter: {
          '_and': ([
            {
              retailer: {
                id: {
                  '_eq': retailer.id
                }
              },
            },
            {
              status: {
                '_eq': 'published'
              }
            },
          ])
        }
      });

      const formattedProvinces = [
        defaultProvince,
        ...(
          provincesResponse.map(province => (
            {
              name: province.name,
              longName: getTranslation(province, { key: 'languages_code', code: languageCode })?.website_name,
              image: `${process.env.REACT_APP_API_URL}/assets/${province.logo}?key=system-large-contain`,
              imageId: province.logo,
              store_url: province.website_url,
              domain: province.domain,
              store_label: getTranslation(province, { key: 'languages_code', code: languageCode })?.website_name,
              translations: province.translations,
            }
          ))
        )
      ];

      setProvinces(formattedProvinces);

      // if on provincial site, then set province settings
      let currentLocation = {
        region: '',
        region_code: formattedProvinces.find(province => province.domain === subdomain)?.name || '',
      };

      // if no province has been saved to state, then geolocate
      if (!province?.name) {
        console.log('retailer', retailer.domains.map(domainRecord => domainRecord.domain).includes(subdomain));
        retailer.domains.map(domainRecord => domainRecord.domain).includes(subdomain)
        // if on main gateway then geolocate user
        if (retailer.domains.map(domainRecord => domainRecord.domain).includes(subdomain)) {
          currentLocation = await api.geolocateUsingIp();
        }

        // match user's province with settings for that province, if any
        const provinceSettings = formattedProvinces.find(province => province.name === currentLocation.region_code);
        // set province
        dispatch(
          updateProvince(
            provinceSettings ||
            defaultProvince
          )
        );

        // open modal  if no province was found
        if (!!!provinceSettings) {
          // setOpen(true);
        }
      }
    } catch (error) {

    }
  }, []);

  return (
      <Stack
        className='province-switcher'
        justifyContent={'center'}
        alignItems={'center'}
        sx={{
          background: 'transparent',
          width: '100%'
        }}
      >
        <Box
          sx={{
            position: 'relative',
            cursor: 'pointer'
          }}
          onClick={handleClickOpen}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            className=''
            sx={{
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              border: '1px solid  #fff',
              borderRadius: '0'
            }}
          >
            <Box
              sx={{
                background: '#000',
                color: '#fff',
                fontSize: '0.7rem',
                fontWeight: 'bold',
                textTransform: 'uppercase',
                px: 1,
              }}
            >
              <Box
                component={'span'}
                sx={{
                  display: {
                    xs: 'none',
                    md: 'block',
                  }
                }}
              >
                {t('storeLocation')}
              </Box>

              <Box
                component={'span'}
                sx={{
                  display: {
                    xs: 'block',
                    md: 'none',
                  }
                }}
              >
                {t('location')}
              </Box>
            </Box>

            <Stack
              justifyContent="center"
              alignItems="center"
              sx={{
                background: '#fff',
                height: '28px',
                width: '28px',
              }}
            >
              { 
                province?.name?.toLowerCase() === 'global' &&
                <Language />
              }

              {
                province?.name?.toLowerCase() !== 'global' &&
                <Typography
                  sx={{
                    color: 'black',
                    fontSize: 16,
                    textTransform: 'uppercase'
                  }}
                  children={province?.name}
                />
              }
            </Stack>
          </Stack>
        </Box>

        <ProvinceDialog
          selectedValue={selectedValue}
          open={open}
          provinces={provinces}
          onClose={handleClose}
        />
      </Stack>
  );
}
