import { forwardRef, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Close } from "@mui/icons-material";

import { Box, Dialog, DialogContent, DialogTitle, IconButton, Slide, Stack, Typography } from "@mui/material";

import TrophyImage from '../../../shared/img/trophy.png';
import Stripes from '../../../shared/img/stripes.png';
import StarIcon from '../../../shared/img/star.png';

import { getTranslation } from "../../../utils/translate";
import { useFetchAchievements } from "../../../hooks/useFetchAchievements";

import { useFetchPoints } from "../../../hooks/useFetchPoints";
import Achievement from "../Achievement";
import Wysiwyg from "../../Wysiwyg";
import { DialogContestTitle } from "../DialogContestTitle";
import { DialogContestFooter } from "../DialogContestFooter";

const Transition = forwardRef(function Transition(props, ref) {
    return (
        <Slide
            direction="up"
            ref={ref}
            timeout={10000}
            unmountOnExit
            {...props}
        />
    );
});

const MyAchievementsDialogoxBox = (props) => {
    const { t } = useTranslation(['gamification']);
    const { code: languageCode } = useSelector(state => state.language);

    const { open = false, onClose } = props;

    const contests = useSelector(state => state.contests);
    const contest = contests?.find(item => item?.id) || null;

    let terms;
    let consent;

    if (contest) {
        const translatedContest = getTranslation(contest, { key: 'languages_code', code: languageCode });

        terms = translatedContest?.terms || '';
        consent = translatedContest?.consent || '';
    }

    const filter = {
        _and: [
            {
                status: {
                    '_eq': 'published'
                }
            },
            ...(contest ? [
                {
                    contests: {
                        contests_id: {
                            '_eq': contest.id
                        }
                    }
                }
            ] : []),
        ]
    };

    const { data } = useFetchAchievements(filter);
    const { totalPoints: points } = useFetchPoints();

    const achievements = data?.map(achievement => {
        const translatedAchievement = getTranslation(achievement, { key: 'languages_code', code: languageCode });
        const imageUrl = achievement.image ?
            `${process.env.REACT_APP_API_URL}/assets/${achievement.image}?key=achievement-image` :
            TrophyImage;

        return {
            id: achievement.id,
            buttonText: translatedAchievement.action_text,
            title: translatedAchievement.title,
            type: achievement.type,
            modalToOpen: achievement.type,
            completed: false,
            points: achievement.points,
            target: achievement.target,
            imageUrl,
            message: translatedAchievement.description,
            success_message: translatedAchievement.success_message,
            achievement,
        }
    }) || [];

    const handleClose = (componentToOpen = null) => {
        if (onClose) {
            onClose(componentToOpen);
        }
    };

    return (
        <Dialog
            className='page-header'
            open={open}
            onClose={() => handleClose('promotion')}
            fullWidth={true}
            TransitionComponent={Transition}
            maxWidth={'xs'}
            scroll="paper"
            PaperProps={{
                sx: {
                    overflow: 'visible',
                    borderRadius: '1rem',
                    marginTop: '5rem',
                },
            }}
        >
            <IconButton
                onClick={() => handleClose('promotion')}
                sx={{
                    color: '#000',
                    position: 'absolute',
                    top: '0.15rem',
                    right: '0.25rem',
                    zIndex: '1',
                }}
            >
                <Close fontSize="interit" />
            </IconButton>

            <DialogTitle>
                <DialogContestTitle />
            </DialogTitle>

            <DialogContent
                sx={{
                    overflowX: 'hidden',
                }}
            >
                <Box
                    sx={{
                        height: {
                            xs: 'auto',
                        },
                        position: 'relative',
                        mb: 2,
                        px: '1.5rem',
                        mx: '-1.5rem',
                    }}
                >
                    <img
                        src={Stripes}
                        alt={'Stripe Art'}
                        style={{
                            height: '100%',
                            width: '100%',
                            position: 'absolute',
                            right: 0,
                            left: 0,
                        }}
                    />

                    <Box
                        sx={{
                            textAlign: 'center',
                            py: 1,
                        }}
                    >
                        <Box
                            sx={{
                                display: 'inline-block',
                                mx: 'auto',
                            }}
                        >
                            <Box
                                sx={{
                                    height: {
                                        xs: 'auto',
                                    },
                                    position: 'relative',
                                }}
                            >
                                <img
                                    src={StarIcon}
                                    alt={'Points Icon'}
                                    style={{
                                        height: '3rem',
                                        width: 'auto',
                                        right: 0,
                                        left: 0,
                                    }}
                                />

                                <Stack
                                    alignItems={'center'}
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        bottom: 0,
                                        right: 0,
                                        left: 0,
                                    }}
                                >
                                    <Typography
                                        component={'div'}
                                        sx={{
                                            fontWeight: 'bold',
                                            fontSize: '1rem',
                                            textAlign: 'center',
                                            my: 'auto',
                                            WebkitTextStroke: '1px #fff',
                                        }}
                                    >
                                        {points}
                                    </Typography>
                                </Stack>
                            </Box>
                        </Box>
                    </Box>
                </Box>

                <Box
                    sx={{
                        textAlign: 'center',
                        mt: 1,
                        mb: 2,
                    }}
                >
                    <Typography
                        variant="h2"
                        sx={{
                            color: '#000',
                            fontSize: '0.8rem',
                            fontWeight: 'bold',
                            textTransform: 'uppercase',
                            textAlign: 'center',
                            mt: 2,
                        }}
                    >
                        <Wysiwyg>
                            {t('achievementsDescription')}
                        </Wysiwyg>
                    </Typography>
                </Box>

                <Stack
                    sx={{
                        mb: 1,
                        mx: '-1.5rem',
                    }}
                    m={{
                        mb: 4,
                        mx: '-1.5rem',
                    }}
                >
                    {
                        achievements.map(achievement => (
                            <Box
                                key={achievement.title}
                            >
                                <Achievement
                                    item={achievement}
                                    totalNumberOfAchievements={achievements.length}
                                />
                            </Box>
                        ))
                    }
                </Stack>

                <DialogContestFooter
                    rules={true}
                    handleClose={() => handleClose('promotion')}
                />
            </DialogContent>
        </Dialog>
    )
}

export const DialogMyAchievements = ({ defaultOpen = false, onClose }) => {
    const handleClose = (componentToOpen = null) => {
        if (onClose) {
            onClose(componentToOpen);
        }
    };

    return (
        <Box
            className='no-print'
        >
            <MyAchievementsDialogoxBox
                open={defaultOpen}
                onClose={handleClose}
                key={defaultOpen}
            />
        </Box>
    )
}